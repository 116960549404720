<template>
<div>
    <div class="modal fade show" style="display: block;background: rgba(0,0,0,0.6);">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header bg-light p-3">
                    <h5 class="modal-title" id="exampleModalLabel">{{$t('check')}}</h5>
                    <button type="button" class="btn-close" @click="closeFun"></button>
                </div>
                <div class="modal-body">

                    <div class="row">

                        <div class="col-lg-12 mb-3">
                            <label class="form-label">{{$t('check')}}{{arguments.type}}</label>
                            <select class="form-control"  v-model="checkVal">
                                <option value="NONE" >{{$t('placeholder1')}}</option>
                                <option value="3" v-if="userGroup=='7'||userGroup=='4'">{{$t('yes')}}</option>
                                <option value="1" v-else>{{$t('yes')}}</option>
                                <option value="2" >{{$t('no')}}</option>
                                <!--<option value="3" v-if="userGroup=='7'">{{$t('boos')}}</option>-->
                            </select>
                        </div>
                        <div class="col-lg-12 mb-3" >
                            <label class="form-label">{{$t('remark')}}</label>
                            <textarea class="form-control" v-model="remark"></textarea>
                        </div>
                    </div>

                </div>
                <div class="modal-footer">
                    <div class="hstack gap-2 justify-content-end">
                        <button type="button" class="btn btn-light" @click="closeFun" >
                            {{$t('close')}}
                        </button>
                        <button type="button" class="btn btn-success" id="add-btn" @click="checkFun">
                            {{$t('confirm')}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
    import Swal from "sweetalert2";
    import {check} from "@/utils/allApi";
    export default {
        data() {
            return {
                checkVal:'NONE',
                remark:'',
                userGroup:'',
            };
        },
        props:{
            arguments:{
                type: Object,
                default:{}
            }
        },
        mounted() {
            this.userGroup = JSON.parse(localStorage.userInfo).user_group
            if(typeof this.arguments != 'object'){
                this.arguments = JSON.parse(this.arguments)
            }
        },
        methods: {
            closeFun(){
                this.$emit('close')
            },
            /**
             * type
             * ‘qingkuan’请款审批
             * ’ruku’入库审批
             * ’chuku’ 入库审批
             * ’zhuanyi’ 库存转移审批
             * ’caigou’ 采购单审批
             * ’pandian’ 采购单审批
             * ’promote’ promote审批
             * */
            checkFun(){
                if(this.checkVal=="NONE"){
                    Swal.fire(this.$t('tooltip1'));
                    return;
                }
                if(this.arguments.type=='promote'&&this.checkVal==3){
                    this.checkVal = 1
                }
                check({
                    mid:this.arguments.id,
                    check_id:this.arguments.check_id,
                    type:this.arguments.type,
                    zt:this.checkVal,
                    check_beizhu:this.remark,
                }).then(res=>{
                    if(this.arguments.type=='qingkuan'||this.arguments.type=='pandian'){
                        this.$emit('succeedFun')
                        return
                    }
                    window.location.reload();
                })
            },

        },
    };
</script>
